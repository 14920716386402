import React from "react";
import Utils from 'utils/Utils';
import './Residence.scss'
import CartoucheInfos from 'components/CartoucheInfos';
import SlugLink from 'components/Router/SlugLink';

function ResidenceEnCours({residence}) {
  const dataResidence=[
    {key:'residence-en-cours',value:residence.title},
    Utils.renderArtistes(residence,false,false),
    {key:null,value:Utils.dates(residence)},
    {image:residence.image,residence:residence.nocrop,visuel_background:residence.visuel_background},
  ];
  return <SlugLink item={residence} tile={true}><div className="residence-bloc">
    <CartoucheInfos data={dataResidence}/>
  </div></SlugLink>;
}
export default ResidenceEnCours;
