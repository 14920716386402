import React, {useContext, useEffect} from "react";
import { useQuery } from '@apollo/client';
import './Blog.scss'
import { DateTime } from "luxon";
import _ from 'lodash'
import Queries from 'utils/Queries';
import { SettingsContext } from 'Providers/SettingsProvider';
import Grid from '@material-ui/core/Grid';
import Image from 'components/Image';
import ExpoMini from 'components/Exposition/ExpoMini';
import ResidenceMini from 'components/Residence/ResidenceMini';
import AcInstanceTile from 'components/Ac/AcInstanceTile';
import Utils from 'utils/Utils';
import Biographie from 'components/Artiste/Biographie';
import Hidden from '@material-ui/core/Hidden';
import { useParams } from "react-router-dom";
import Mod from 'components/Mod';
import Ressources from 'components/Ressources';
import Partenaires from 'components/Partenaires';
import Share from 'components/Share';

export const datesBlog=(blog)=>[
  {titre:'',date:blog.date,time_from:blog.time_from,time_to:blog.time_to},
  ...blog.repetitions
];
export const parseYear=(evt)=>{
  const { date }=evt;
  const df=DateTime.fromISO(date);
  const fdf=df.toFormat("y")===DateTime.now().toFormat("y") ? "" : df.toFormat("y");
  return fdf;
};
export const parseMonth=(evt,lang)=>{
  const { date }=evt;
  const df=DateTime.fromISO(date);
  const fdf=df.setLocale(lang).toFormat("LLLL");
  return fdf;
};
export const parseDay=(evt)=>{
  const { date }=evt;
  const df=DateTime.fromISO(date);
  const fdf=df.toFormat("d");
  return fdf;
};
export const parseDates=(blog,lang)=>{
  const dates=[...datesBlog(blog)].sort((a,b)=>a.date-b.date);
  let res=_.groupBy(dates,(o)=>o.titre || '');
  Object.keys(res).forEach((titre) => {
    res[titre]=_.groupBy(res[titre],(evt)=>parseYear(evt));
    Object.keys(res[titre]).forEach((year)=>{
      res[titre][year]=_.groupBy(res[titre][year],(evt)=>parseMonth(evt,lang));
      Object.keys(res[titre][year]).forEach((month)=>{
        res[titre][year][month].forEach((item, i) => {
            res[titre][year][month][i]=parseDay(item);
        });
      });
    });
  });
  return res;
};
export const displayDates=(dates,t,prefix='')=>{
  return <>
  {Object.keys(dates).map((titre,it) => <React.Fragment key={it}>
    {titre && <div className="blog-evt-titre">{titre} :</div>}
    <div className="blog-evt-horaires">
      {prefix+' '}
      {Object.keys(dates[titre]).sort((a,b)=>parseInt(a || 0)-parseInt(b || 0)).map((year,iy)=><React.Fragment key={iy}>
        {iy>0 && ', '}
        {Object.keys(dates[titre][year]).sort().map((month,im)=><React.Fragment key={im}>
          {im>0 && ', '}
          {dates[titre][year][month].sort((a,b)=>parseInt(a)-parseInt(b)).map((day, id) =>
            <React.Fragment key={id}>{id>0 && (id===dates[titre][year][month].length-1 ? ` ${t('et')} `:', ')}{day}</React.Fragment>
          )}
          {month && ' '+month}
        </React.Fragment>)}
        {year && ' '+year}
      </React.Fragment>)}
    </div>
  </React.Fragment>)}
  </>;
};

function Blog({id}) {
  const { slug }=useParams();
  const { t, lang, translate, updateLoadState, fetchedList } = useContext(SettingsContext);
  const { refetch, networkStatus, data } = useQuery(Queries.blog,{
    variables: { slug },
  });
  const uuid='blog/'+slug;
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  let blog=data ? data.blogs[0] : null;
  useEffect(()=>{
    if (blog && blog.title) document.title=blog.title
  },[blog]);
  return blog ? <div className="blog">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <div className="spacer5"></div>
          <div className="blog-visuel">
            <Image credits file={blog.visuel} thumbnailKey={"carre-small-nocrop"} background={blog.visuel_background || 'white'}/>
          </div>
          <Hidden xsDown>
            {blog.expositions.length>0 && <div className="cartouche-infos">
              <div className="cartouche-infos-label">{t('expositions')}</div>
              {blog.expositions.map(({expositions_id:expo})=>expo ? <ExpoMini key={expo.id} expo={expo} mini={true}/> : null)}
              <div className="spacer"/>
            </div>}
            {blog.residences.length>0 && <div className="cartouche-infos">
              <div className="cartouche-infos-label">{t('residences')}</div>
              {blog.residences.map(({residences_id:residence})=>residence ? <ResidenceMini key={residence.id} residence={residence} mini={true}/> : null)}
              <div className="spacer"/>
            </div>}
            {blog.ac_instances.length>0 && <div className="cartouche-infos">
              <div className="cartouche-infos-label">{t('eac')}</div>
              {blog.ac_instances.map(({ac_instances_id:acInstance})=>acInstance ? <AcInstanceTile size='mini' key={acInstance.id} acInstance={acInstance} mini={true}/> : null)}
            </div>}
          </Hidden>
        </Grid>
        <Grid item xs={12} sm={8}>
          <div className="spacer5"></div>
          <div className="blog-dates">
              {displayDates(parseDates(blog,lang),t,t('publie-le'))}
          </div>
          <div className="blog-type">{blog.types.map((type,i)=><span key={i}>{i>0 && ', '}{translate(type.types_id,'name')}</span>)}</div>
          <div className="blog-titre">{blog.title}</div>
          <div className="blog-artistes">{Utils.renderArtistes(blog,true,false).value}</div>
          <div className="blog-lieu">{blog.lieu}</div>
          <div className="blog-ville">{blog.ville}</div>
          <div className="blog-texte select">
            {Utils.parseWithLinks(translate(blog,'text'))}
          </div>
          <Share/>
          {blog.artistes.map((artiste)=>artiste.artistes_id && artiste.artistes_id.bio ? <Biographie key={artiste.artistes_id.id} artiste={artiste.artistes_id}/> : null)}
          <Ressources item={blog}/>
          <Partenaires item={blog}/>
          <Hidden smUp>
            {blog.expositions.length>0 && <div className="cartouche-infos">
              <div className="cartouche-infos-label">{t('expositions')}</div>
              {blog.expositions.map(({expositions_id:expo})=>expo ? <ExpoMini key={expo.id} expo={expo} mini={true}/> : null)}
              <div className="spacer"/>
            </div>}
            {blog.residences.length>0 && <div className="cartouche-infos">
              <div className="cartouche-infos-label">{t('residences')}</div>
              {blog.residences.map(({residences_id:residence})=>residence ? <ResidenceMini key={residence.id} residence={residence} mini={true}/> : null)}
              <div className="spacer"/>
            </div>}
            {blog.ac_instances.length>0 && <div className="cartouche-infos">
              <div className="cartouche-infos-label">{t('eac')}</div>
              {blog.ac_instances.map(({ac_instances_id:acInstance})=>acInstance ? <AcInstanceTile size='mini' key={acInstance.id} acInstance={acInstance} mini={true}/> : null)}
            </div>}
          </Hidden>
        </Grid>
      </Grid>
    <Mod url={blog.__typename+'/'+blog.id} refresh={refetch}/>
  </div> : '';
}
export default Blog;
