import React, {useContext,useState,useEffect} from "react";
import { useQuery } from '@apollo/client';
import './Ac.scss'
import Queries from 'utils/Queries';
import Grid from '@material-ui/core/Grid';
import Ressources from 'components/Ressources';
import Partenaires from 'components/Partenaires';
import CartoucheInfos from 'components/CartoucheInfos';
import AcInstanceTile from './AcInstanceTile';
import { SettingsContext } from 'Providers/SettingsProvider';
import SlugLink from 'components/Router/SlugLink';
import Pagination from 'components/Pagination';
import Conf from 'utils/Conf';
import Utils from 'utils/Utils';
import { useParams } from "react-router-dom";
import Mod from 'components/Mod';
import Share from 'components/Share';

function AcProject() {
  const [ state, setState ] = useState({});
  const { slug }=useParams();
  const { translate, t, updateLoadState, fetchedList, appState } = useContext(SettingsContext);
  const { refetch, networkStatus, data } = useQuery(Queries.ac_project,{
    variables: { slug },
  });
  const uuid='acProject/'+slug;
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  useEffect(()=>{
    if (data) {
        setState({
          ac:data.ac,
          acProject:data.ac_project[0],
          propositions:data.propositions,
          acInstances:data.ac_project[0].ac_instances.filter((o)=>o.ac_instances_id),
        });
    }
  },[data]);
  const { ac, acProject, propositions, acInstances }=state;
  const dataAc=acProject ? [
    {key:null,value:translate(acProject,'title')},
  ]: [];
  const nb=acInstances ? acInstances.length : 0;
  const page = (appState.paginations[uuid] && appState.paginations[uuid].page) || 1;
  useEffect(()=>{
    if (acProject && acProject.title) document.title=acProject.title
  },[acProject]);
  return acProject ? <div className="ac-project">
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <div className="ac-type">{translate(ac,'title')}</div>
        <CartoucheInfos data={dataAc}/>
        <div className="ac-text select">
          {Utils.parseWithLinks(acProject ? translate(acProject,'text') : '')}
        </div>
        <Share/>
        <Ressources item={acProject}/>
        <Partenaires item={acProject}/>
        <hr />
        {nb>0 && <Pagination paginationId={uuid} page={page} nb={nb} typeSingulier='ac-instance' typePluriel='ac-instances' perPage={Conf.perPage}/>}
        {acInstances && <Grid container spacing={2}>
          {acInstances.map((acInstance,i)=>{
            const from=(page-1)*Conf.perPage+1;
            const to=Math.min(nb,page*Conf.perPage);
            if (i+1>=from && i+1<=to) {
              return <Grid key={acInstance.ac_instances_id.id} item xs={12} md={6}>
                <AcInstanceTile acInstance={acInstance.ac_instances_id}/>
              </Grid>;
            }
            return null;
          })}
        </Grid>}
      </Grid>
      {propositions && <Grid item xs={12} md={4}>
        <div className="ac-project-sidebar">
          <div className="ac-type">{t('actions')}</div>
          {propositions.filter((o)=>o.construit==='non').map((proposition)=><div className={proposition.id===acProject.id ? 'menu-ac active' : 'menu-ac'} key={proposition.id}>
            <SlugLink item={proposition}>{translate(proposition,'title')}</SlugLink>
          </div>)}
          <div className="spacer"/>
          <div className="ac-type">{t('cette-saison')}</div>
          {propositions.filter((o)=>o.construit==='oui').map((proposition)=><div className={proposition.id===acProject.id ? 'menu-ac active' : 'menu-ac'} key={proposition.id}>
            <SlugLink item={proposition}>{translate(proposition,'title')}</SlugLink>
          </div>)}
        </div>
      </Grid>}
    </Grid>
    <Mod url={acProject.__typename+'/'+acProject.id} refresh={refetch}/>
  </div> : '';
}
export default AcProject;
