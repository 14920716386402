import React, {useContext} from "react";
import Utils from 'utils/Utils';
import './BlogTile.scss'
import { SettingsContext } from 'Providers/SettingsProvider';
import SlugLink from 'components/Router/SlugLink';
import Image from 'components/Image';

function BlogTile({blog,size='big'}) {
  const { t, translate } = useContext(SettingsContext);
  return blog ? <SlugLink item={blog} tile={true}>
  { size==='big' && <div className="blog-tile">
      <div className="blog-tile-head">
        <div className="blog-tile-type">{t('actualite')}</div>
        <div className="blog-tile-titre">
          {blog.title}
          <div>{Utils.renderArtistes(blog,false,false).value}</div>
        </div>
      </div>
      <Image file={blog.visuel} thumbnailKey={"carre-small"} background={blog.visuel_background || 'white'} crop={blog.crop}/>
        <div className="blog-tile-resume select">
        {Utils.parseWithLinks(translate(blog,'resume'))}
      </div>
  </div>}
  { size==='mini' && <div className="blog-tile">
      <div className="blog-tile-mini-titre">
        {blog.title}
        <div>{Utils.renderArtistes(blog,false,false).value}</div>
      </div>
      <div className="blog-tile-mini-resume select">
        {Utils.parseWithLinks(translate(blog,'resume'))}
      </div>
  </div>}
  </SlugLink> : '';
}
export default BlogTile;
