import React, { useContext, useEffect } from "react";
import { useQuery } from '@apollo/client';
import './Residence.scss'
import Queries from 'utils/Queries';
import Biographie from 'components/Artiste/Biographie';
import Ressources from 'components/Ressources';
import Partenaires from 'components/Partenaires';
import CartoucheInfos from 'components/CartoucheInfos';
import Utils from 'utils/Utils';
import Grid from '@material-ui/core/Grid';
import RdvTile from 'components/Rdv/RdvTile';
import BlogTile from 'components/Blog/BlogTile';
import { SettingsContext } from 'Providers/SettingsProvider';
import Image from 'components/Image';
import { useParams } from "react-router-dom";
import Mod from 'components/Mod';
import ExpoMini from 'components/Exposition/ExpoMini';
import Share from 'components/Share';

import SwiperCore, { Navigation, Autoplay, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
SwiperCore.use([Navigation, Autoplay, A11y]);

function Residence() {
  const { slug }=useParams();
  const { t, translate, updateLoadState, fetchedList } = useContext(SettingsContext);
  const { refetch, networkStatus, data } = useQuery(Queries.residence,{
    variables: { slug },
  });
  const uuid='residence/'+slug;
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const residence=data ? data.residences[0] : null;
  const dataResidence=residence ? [
    {key:'residence',value:translate(residence,'title')},
    Utils.renderArtistes(residence,true,false),
    {key:null,value:Utils.dates(residence)},
  ]: [];
  const rdvs=residence ? residence.rdvs.filter((o)=>o.rdvs_id && o.rdvs_id.agenda!=='blog') : [];
  const blogs=residence ? residence.rdvs.filter((o)=>o.rdvs_id && o.rdvs_id.agenda==='blog') : [];
  useEffect(()=>{
    if (residence && residence.title) document.title=residence.title
  },[residence]);
  return residence ? <div className="residence">
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <CartoucheInfos data={dataResidence}/>
        <div className="cartouche-infos">
          <div className="cartouche-infos-image">
          {(residence.image || (residence.diaporama && residence.diaporama.length>0)) && <div className="residence-diaporama">
            {
            residence.diaporama && residence.diaporama.length>0 ?
              <Swiper
              speed={800}
              loop
              autoplay={{
                delay: 3000,
              }}
              enabled={residence.diaporama.length>1}
              navigation={residence.diaporama.length>1}
              >
                {residence.diaporama.map((diap,i)=>{
                    const img=diap.directus_files_id;
                    return <SwiperSlide key={img.id}>
                      <Image credits file={img} thumbnailKey={"16-9"} ratio={0.5625}/>
                    </SwiperSlide>;
                })}
              </Swiper>
              :
              (residence.image ? <Image credits file={residence.image} thumbnailKey={"16-9"} ratio={0.5625} nocrop={true} align='left'/> : null)
            }
          </div>}
        </div>
        </div>
        </Grid>
      <Grid item xs={12} md={4}>
      </Grid>
    </Grid>
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <div className="residence-text select">
          {Utils.parseWithLinks(residence ? (residence.text ? translate(residence,'text') : (residence.resume ? translate(residence,'resume'): '')) : '')}
        </div>
        <Share/>
        {residence.artistes.map((artiste)=>artiste.artistes_id && artiste.artistes_id.bio ? <Biographie key={artiste.artistes_id.id} artiste={artiste.artistes_id}/>: null)}
        <Ressources item={residence}/>
        <Partenaires item={residence}/>
      </Grid>
      <Grid item xs={12} md={4}>
        <div className="billets">
          {residence.expositions.length>0 && residence.expositions.map(({expositions_id:expo})=><div key={expo.id}>
            <div className="type-billet">{t('restitution')}</div>
            <ExpoMini key={expo.id} expo={expo} mini={true} resume={true} lieu={true}/>
          </div>)}
          {residence.expositions.length>0 && <div className="spacer"/>}
          {rdvs.length>0 && <div className="type-billet">{t('rendez-vous')}</div>}
          {rdvs.map((rdv,i)=><RdvTile key={i} rdv={rdv.rdvs_id} size="mini"/>)}
          {rdvs.length>0 && <div className="spacer"/>}
          {blogs.length>0 && <div className="type-billet">{t('actualites')}</div>}
          {blogs.map((blog,i)=><BlogTile key={i} blog={blog.rdvs_id} size="mini"/>)}
        </div>
      </Grid>
    </Grid>
    <Mod url={residence.__typename+'/'+residence.id} refresh={refetch}/>
  </div> : '';
}
export default Residence;
