import React, { useContext } from "react";
import './Page.scss'
import CartoucheInfos from 'components/CartoucheInfos';
import { SettingsContext } from 'Providers/SettingsProvider';
import Grid from '@material-ui/core/Grid';
import Utils from 'utils/Utils';
import Mod from 'components/Mod';
import Image from 'components/Image';
import Share from 'components/Share';

function PageInner({page,type=true,refresh=()=>null}) {
  const { translate } = useContext(SettingsContext);
  const dataPage=page ? [
    {...(type ? {key_tr:page.rubrique ? translate(page.rubrique,'title') : translate(page,'title')} : {}),value:translate(page,'title')},
  ]: [];
  return page ? <div className='page'>
  <Grid container spacing={2}>
    <Grid item xs={12} md={8}>
      <CartoucheInfos data={dataPage}/>
      {page.visuel && <div className="page-visuel">
          <Image credits file={page.visuel} thumbnailKey={"16-9"} ratio={0.5625} nocrop={true} align='left'/>
      </div>}
      <div className="page-text select">
        {Utils.parseWithLinks(translate(page,'text'))}
      </div>
      <Share/>
    </Grid>
  </Grid>
  <Mod url={page.__typename+'/'+page.id} refresh={refresh}/>
  </div> : '';
}
export default PageInner;
