import React, { useContext, useRef, useEffect } from "react";
import './Rechercher.scss';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Conf from 'utils/Conf';
import Queries from 'utils/Queries';
import { SettingsContext } from 'Providers/SettingsProvider';
import {
  useNavigate
} from "react-router";
import axios from 'axios';

function Rechercher({ className='', xs }) {
  const { t, filter, setFilter, setAppState, appState } = useContext(SettingsContext);
  useEffect(() => {
    const localSearchBase = localStorage.getItem('lelait-searchBase');
    if (localSearchBase) setAppState((state)=>{return {...state, searchBase:JSON.parse(localSearchBase)}});
    axios({
      url: Conf.apiUrl+"/graphql",
      method: 'post',
      data: {
        query: Queries.search,
      },
      headers: { Authorization: `Bearer ${Conf.authToken}` }
    }).then((result) => {
      localStorage.setItem('lelait-searchBase',JSON.stringify(result.data.data));
      setAppState((state)=>{return {...state, searchBase:result.data.data}});
    });
  }, [setAppState,appState.refresh]);
  let classes=className+" rechercher";
  const { search, ...otherFilter } = filter;
  const ref=useRef();
  let navigate = useNavigate();
  const handleChange = (e)=>{
      ref.current.focus();
      if ( e.target.value!=='' && window.location.pathname!=='/recherche') {
          navigate("/recherche");
      }
      setFilter({search:e.target.value, ...otherFilter});
  };
  const handleFocus = (e)=>{
      if ( e.target.value!=='' && window.location.pathname!=='/recherche') {
          navigate("/recherche");
      }
  };
  const handleReset = (e)=>{
      if (search!=='') setFilter({search:'', ...otherFilter});
  };
  if (xs) classes+=" xs";
  return <div className={classes}>
      <Grid container alignContent="flex-end">
          <Grid item style={{width:30}}>
              <IconButton aria-label="search" onClick={handleReset}>
                {search==='' && <SearchIcon/>}
                {search!=='' && <CloseIcon/>}
              </IconButton>
          </Grid>
          <Grid item xs>
              <TextField inputRef={ref} id="standard-basic" label={!xs && t('rechercher')} onFocus={handleFocus} onChange={handleChange} value={search} fullWidth/>
          </Grid>
      </Grid>
  </div>;
}

export default Rechercher;
