import React, { useContext } from "react";
import Utils from 'utils/Utils';
import './Exposition.scss'
import CartoucheInfos from 'components/CartoucheInfos';
import SlugLink from 'components/Router/SlugLink';
import { SettingsContext } from 'Providers/SettingsProvider';

function ExpoMini({expo,mini=false,resume=false,lieu=false}) {
  const { translate } = useContext(SettingsContext);
  const dataExpo=[
    {key:null,value:expo.title,size:'medium'},
    Utils.renderArtistes(expo,false,false,'medium'),
    {key:null,value:Utils.dates(expo),size:'small'},
    ...(lieu && expo.lieu ? [{key:null,value:expo.lieu+(expo.ville && ', '+expo.ville),size:'small'}] : []),
    ...(resume ? [{key:null,value:Utils.parseWithLinks(translate(expo,'resume')),size:'xsmall'}] : []),
  ];
  return <SlugLink item={expo} tile={true}><div className={"expo-bloc"+(mini ? ' mini' : '')}>
    <CartoucheInfos data={dataExpo}/>
  </div></SlugLink>;
}
export default ExpoMini;
