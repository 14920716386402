import React from "react";
import './Header.scss';

function Header({ data }) {
  return <div className="header">

  </div>;
}

export default Header;
