import React, { useContext, useEffect } from "react";
import './LangSwitch.scss';
import { useQuery } from '@apollo/client';
import { SettingsContext } from 'Providers/SettingsProvider';
import Queries from 'utils/Queries';

function LangSwitch({align}) {
  const { lang, setLang, updateLoadState, fetchedList } = useContext(SettingsContext);
  const { refetch, networkStatus, data } = useQuery(Queries.langswitch);
  const uuid='langswitch';
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const langs=data ? data.languages : [];
  const actualLangs=[{code:'fr'},...langs];
  let classes="lang-switch";
  if (align) classes+=" "+align;
  return data ? <div className={classes}>
    {actualLangs && actualLangs.map((l,i)=><React.Fragment key={l.code}>{i>0 && ' | '}<span className={lang===l.code ? 'active' : ''} onClick={()=>setLang(l.code)}>{l.code}</span></React.Fragment>)}
  </div> : '';
}

export default LangSwitch;
