import React, { useEffect, useState } from "react";
import './MenuMobile.scss';
import {
  useLocation,
} from "react-router-dom";
import {ReactComponent as Burger} from 'imgs/Burger.svg';
import {ReactComponent as Close} from 'imgs/Close.svg';
import MenuLeft from 'components/MenuLeft';

function MenuMobile() {
  const [ open, setOpen ] = useState(false);
  const {pathname}=useLocation();
  useEffect(()=>setOpen(false),[pathname]);
  useEffect(()=>{
    if (open) {
      window.scrollTo(0,86);
      document.body.style.overflow='hidden';
    } else {
      document.body.style.overflow='visible';
    }
  },[open]);
  let classes='menu-mobile';
  return <div className={classes}>
    <div className="menu-mobile-btn">
      {open ? <Close onClick={()=>setOpen(false)}/> : <Burger onClick={()=>setOpen(true)}/>}
    </div>
    {open && <div className="menu-mobile-over">
      <div className="menu-mobile-over-inner">
        <MenuLeft/>
      </div>
    </div>}
  </div>;
}

export default MenuMobile;
