import React, { useContext, useEffect } from "react";
import { useQuery } from '@apollo/client';
import './Page.scss'
import Queries from 'utils/Queries';
import { SettingsContext } from 'Providers/SettingsProvider';
import PageInner from 'components/Page/PageInner';
import { useParams } from "react-router-dom";

function Page() {
  const { slug } = useParams();
  const { setCurrentRub, updateLoadState, fetchedList } = useContext(SettingsContext);
  const { refetch, networkStatus, data } = useQuery(Queries.page,{
    variables: { slug },
  });
  const uuid='page/'+slug;
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const page=data ? data.pages[0] : null;
  useEffect(()=>{
    if (page && page.rubrique) {
      setCurrentRub(page.rubrique.id);
    } else {
      setCurrentRub(null);
    }
    return ()=>setCurrentRub(null);
  },[page,setCurrentRub]);
  useEffect(()=>{
    if (page && page.title) document.title=page.title
  },[page]);
  return page ? <PageInner page={page} refresh={refetch}/> : '';
}
export default Page;
