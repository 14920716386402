import React, { useContext, useState, useEffect } from "react";
import { SettingsContext } from 'Providers/SettingsProvider';
import './Footer.scss';
import FooterInner from 'components/Footer/FooterInner';

function FooterClone() {
  const { filter, tick } = useContext(SettingsContext);
  const [ anim, setAnim ] = useState(true);
  const [ show, setShow ] = useState(true);
  const [ scrollY, setScrollY ] = useState(0);
  useEffect(()=>{
      const handleScroll=()=>{
          setScrollY(window.scrollY);
      };
      document.addEventListener('scroll',handleScroll);
      return ()=>document.removeEventListener('scroll',handleScroll);
  },[setScrollY]);
  let classes="footer clone";
  if (scrollY<10) classes+=" top";
  const scrollDown=()=>{
      setAnim(false);
      window.scroll(0,document.body.clientHeight);
      console.log(document.body.clientHeight);
      setTimeout(()=>setAnim(true),1000);
  }
  useEffect(()=>{
    const footer=document.getElementById('footer');
    if (footer) setShow(footer.getBoundingClientRect().top>window.innerHeight);
  },[filter,tick]);
  if (show) classes+=" show";
  if (anim) classes+=" anim";
  return <div className={classes} onClick={scrollDown}>
      <FooterInner/>
  </div>;
}

export default FooterClone;
