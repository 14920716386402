import React, { useContext, useEffect } from "react";
import './Rechercher.scss';
import { SettingsContext } from 'Providers/SettingsProvider';
import Utils from 'utils/Utils';
import Result from 'components/Rechercher/Result';

function Results() {
  const { t, filter, appState } = useContext(SettingsContext);
  const { search } = filter;
  const { expositions, pages, residences, editions, blogs, rdvs, ac_project, ac_instances, eac_docs } = appState.searchBase;
  const compute=(collections)=>{
    let res={};
    let normalizedSearch=Utils.accentsTidyLw(search);
    Object.keys(collections).forEach((k)=>{
      res={...res,[k]:collections[k] ? collections[k].filter((o)=>{
        if (search.length<1) return false;
        if (Utils.accentsTidyLw(o.title).indexOf(normalizedSearch)!==-1) return true;
        if (o.artistes){
          for(let i=0; i<o.artistes.length;i++){
            let artiste=o.artistes[i].artistes_id;
            if (artiste && artiste.name && Utils.accentsTidyLw(artiste.name).indexOf(normalizedSearch)!==-1) {
              return true;
            }
          };
        }
        return false;
      }) : []};
    });
    return res;
  }
  useEffect(()=>{
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  },[search]);
  const results=compute({ expositions, pages, residences, editions, blogs, rdvs, ac_project, ac_instances, eac_docs });
  useEffect(()=>{
    document.title=t('rechercher')
  },[t]);
  return <div className="results">
    {!search && <div>{t('saisir-recherche')}</div>}
    {search && results.expositions.length+results.pages.length+results.residences.length+results.editions.length+results.blogs.length+results.rdvs.length+results.ac_project.length+results.ac_instances.length+results.eac_docs.length===0 && <div>{t('no-result')}</div>}
    <Result collection={results.expositions} typeSingulier='exposition' typePluriel='expositions'/>
    <Result collection={results.residences} typeSingulier='residence' typePluriel='residences'/>
    <Result collection={results.editions} typeSingulier='edition' typePluriel='editions'/>
    <Result collection={results.blogs} typeSingulier='actualite' typePluriel='actualites'/>
    <Result collection={results.rdvs} typeSingulier='rendez-vous' typePluriel='rendez-vous'/>
    <Result collection={results.ac_project} typeSingulier='ac-project-long' typePluriel='ac-projects-long'/>
    <Result collection={results.ac_instances} typeSingulier='ac-instance-long' typePluriel='ac-instances-long'/>
    <Result collection={results.pages} typeSingulier='page' typePluriel='pages'/>
    <Result collection={results.eac_docs} typeSingulier='eac-doc' typePluriel='eac-docs'/>
    <div className="result-spacer"></div>
  </div>;
}

export default Results;
