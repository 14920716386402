import React, {useEffect,useState,useRef,useCallback} from "react";
import './Animation.scss';
import Conf from 'utils/Conf';

function Animation({frames,reset=0}) {
  const [ counter,setCounter ] = useState(0);
  const intervalId=useRef(null);
  const startAnim=useCallback(()=>{
    intervalId.current= setInterval(()=>{
        setCounter((c)=>c+1);
    }, 1000);
  },[setCounter]);
  const stopAnim=useCallback(()=>{
    clearInterval(intervalId.current);
  },[]);
  useEffect(()=>{
    startAnim();
    return stopAnim();
  },[startAnim,stopAnim]);
  useEffect(()=>{
    stopAnim();
    setCounter(0);
    startAnim();
  },[reset,stopAnim,startAnim]);
  const step=counter%(frames.length+5);
  return frames.length>0 ? <div className={'animation step-'+step}>
    {[...frames].sort((a,b)=>a.sort-b.sort).map((frame,i)=>{
      const visible=i<=step;
      return <img className={visible ? 'visible' : ''} key={frame.directus_files_id.id} alt="" src={`${Conf.apiUrl}/assets/${frame.directus_files_id.id}`} crossOrigin="anonymous"/>
    })}
  </div> : '';
}

export default Animation;
