import React, {useContext} from "react";
import Utils from 'utils/Utils';
import './Edition.scss'
import CartoucheInfos from 'components/CartoucheInfos';
import SlugLink from 'components/Router/SlugLink';
import { SettingsContext } from 'Providers/SettingsProvider';

function EditionBloc({edition}) {
  const { translate } = useContext(SettingsContext);
  const dataEdition=[
    {key_tr:edition.type ? translate(edition.type,'nom') : '',value:edition.title},
    Utils.renderArtistes(edition,false,false),
    {image:edition.visuel,nocrop:edition.nocrop,visuel_background:edition.visuel_background},
  ];
  return <SlugLink item={edition} tile={true}><div className="edition-bloc">
    <CartoucheInfos data={dataEdition}/>
  </div></SlugLink>;
}
export default EditionBloc;
