import { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { SettingsContext } from 'Providers/SettingsProvider';
import _ from 'lodash';
let pagesScrollY={};

export default function ScrollToTop() {
  const { appState, setAppState } = useContext(SettingsContext);
  const { pathname } = useLocation();
  useEffect(() => {
    setTimeout(()=>{
      const scrollY=pagesScrollY[pathname] || 0;
      window.scrollTo({
        top: scrollY,
        left: 0,
        behavior: 'auto'
      });
    },0);
  }, [pathname]);
  useEffect(()=>{
      const handleScroll=_.debounce(()=>{
        pagesScrollY={ ...pagesScrollY, [pathname]:window.scrollY };
        setAppState((state)=>{return{...state,pagesScrollY}});
      },100);
      document.addEventListener('scroll',handleScroll);
      return ()=>document.removeEventListener('scroll',handleScroll);
  },[pathname,setAppState]);
  useEffect(()=>{
    pagesScrollY={...appState.pagesScrollY};
  },[appState])
  return null;
}
