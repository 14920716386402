import React, {useContext} from "react";
import Utils from 'utils/Utils';
import './RdvTile.scss'
import { parseDates,displayDates,parseTime } from 'components/Rdv/Rdv';
import { SettingsContext } from 'Providers/SettingsProvider';
import SlugLink from 'components/Router/SlugLink';
import Image from 'components/Image';

function RdvTile({rdv,size='big',className=null}) {
  const { t, lang, translate } = useContext(SettingsContext);
  return rdv ? <SlugLink item={rdv} tile={true}>
  { size==='big' && <div className="rdv-tile">
      <div className="rdv-tile-head">
        <div className="rdv-tile-type">{rdv.types.map((type,i)=><span key={i}>{i>0 && ', '}{translate(type.types_id,'name')}</span>)}</div>
        <div className="rdv-tile-titre">
          {rdv.title}
          <div>{Utils.renderArtistes(rdv,false,false).value}</div>
        </div>
      </div>
      <Image file={rdv.visuel} thumbnailKey={"carre-small"} background={rdv.visuel_background} crop={rdv.crop}/>
      <div className="rdv-tile-dates">
          {Utils.dates(rdv)} – {parseTime(rdv,true)}
      </div>
      <div className="rdv-tile-lieu">{rdv.lieu}</div>
      <div className="rdv-tile-ville">{rdv.ville}</div>
      <div className="rdv-tile-resume select">
        {Utils.parseWithLinks(translate(rdv,'resume'))}
      </div>

  </div>}
  { size==='mini' && <div className={"rdv-tile"+(className ? ' '+className : '')}>
      <div className="rdv-tile-type">{rdv.types.map((type,i)=><span key={i}>{i>0 && ', '}{translate(type.types_id,'name')}</span>)}</div>
      <div className="rdv-tile-mini-titre">
        {rdv.title}
        <div>{Utils.renderArtistes(rdv,false,false).value}</div>
      </div>
      <div className="rdv-tile-mini-dates">
          {displayDates(parseDates(rdv,lang),t)}
      </div>
      {(rdv.lieu || rdv.ville) && <div className="rdv-tile-mini-lieu">{rdv.lieu}{rdv.lieu && rdv.ville && ', '}{rdv.ville}</div>}
      <div className="rdv-tile-mini-resume select">
        {Utils.parseWithLinks(translate(rdv,'resume'))}
      </div>
  </div>}
  </SlugLink> : '';
}
export default RdvTile;
