import React from "react";
import Utils from 'utils/Utils';
import './Exposition.scss'
import CartoucheInfos from 'components/CartoucheInfos';
import SlugLink from 'components/Router/SlugLink';

function ExpoAvenir({expo, size='medium'}) {
  const dataExpo=[
    {key:'exposition-a-venir',value:expo.title,size},
    Utils.renderArtistes(expo,false,false,size),
    {key:null,value:Utils.dates(expo),size:'small'},
  ];
  return <SlugLink item={expo} tile={true}><div className="expo-bloc">
    <CartoucheInfos data={dataExpo}/>
  </div></SlugLink>;
}
export default ExpoAvenir;
