import React from "react";
import Utils from 'utils/Utils';
import './Exposition.scss'
import CartoucheInfos from 'components/CartoucheInfos';
import SlugLink from 'components/Router/SlugLink';
import { DateTime } from "luxon";

function ExpositionTile({expo}) {
  let surtitre='';
  if(DateTime.fromISO(expo.date_to)>=DateTime.now() && DateTime.fromISO(expo.date_from)<=DateTime.now()) surtitre='expo-en-cours';
  if(DateTime.fromISO(expo.date_to)<DateTime.now()) surtitre='expo-passee';
  if(DateTime.fromISO(expo.date_from)>DateTime.now()) surtitre='expo-a-venir';
  const dataExpo=[
    {key:surtitre,value:expo.title},
    Utils.renderArtistes(expo,false,false),
    {key:null,value:Utils.dates(expo)},
    (expo.animation ? {animation:expo.animation} : {image:expo.image,credits:false,nocrop:expo.nocrop,visuel_background:expo.visuel_background}),
  ];
  return <div className="expo-bloc">
    <SlugLink item={expo}><CartoucheInfos data={dataExpo}/></SlugLink>
  </div>;
}
export default ExpositionTile;
