import React, { useContext, useEffect } from "react";
import './Residences.scss'
import ResidenceEnCours from 'components/Residence/ResidenceEnCours';
import ResidencePassee from 'components/Residence/ResidencePassee';
import ResidenceAvenir from 'components/Residence/ResidenceAvenir';
import Grid from '@material-ui/core/Grid';
import { useQuery } from '@apollo/client';
import Queries from 'utils/Queries';
import { DateTime } from "luxon";
import { SettingsContext } from 'Providers/SettingsProvider';
import Conf from 'utils/Conf';
import Pagination from 'components/Pagination';
import Mod from 'components/Mod';

function Residences() {
  const { updateLoadState, fetchedList, appState, t } = useContext(SettingsContext);
  const { refetch, networkStatus, data } = useQuery(Queries.residences);
  const uuid='residences';
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  let residencesEnCours= data ? data.residences.map((o)=>({...o,typeResidence:'enCours'})).filter((o)=>DateTime.fromISO(o.date_to)>=DateTime.now() && DateTime.fromISO(o.date_from)<=DateTime.now()).sort((a,b)=>DateTime.fromISO(a.date_from)-DateTime.fromISO(b.date_from)) : [];
  let residencesPassees= data ? data.residences.map((o)=>({...o,typeResidence:'passees'})).filter((o)=>DateTime.fromISO(o.date_to)<DateTime.now()).sort((a,b)=>DateTime.fromISO(b.date_from)-DateTime.fromISO(a.date_from)) : [];
  let residencesAvenir= data ? data.residences.map((o)=>({...o,typeResidence:'aVenir'})).filter((o)=>DateTime.fromISO(o.date_from)>DateTime.now()).sort((a,b)=>DateTime.fromISO(a.date_from)-DateTime.fromISO(b.date_from)) : [];
  const nb=residencesPassees ? residencesPassees.length : 0;
  const page = (appState.paginations[uuid] && appState.paginations[uuid].page) || 1;
  useEffect(()=>{
    document.title=t('residences')
  },[t]);
  return (<div className="residences">
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        {residencesEnCours.map((residence,i)=>{
          const from=(page-1)*Conf.perPage+1;
          const to=Math.min(nb,page*Conf.perPage);
          if (i+1>=from && i+1<=to) {
            return <ResidenceEnCours key={residence.id} residence={residence}/>;
          }
          return null;
        })}
        {nb>0 && <Pagination paginationId={uuid} page={page} nb={nb} typeSingulier='residence-passee' typePluriel='residences-passees' perPage={Conf.perPage}/>}
        {residencesPassees.map((residence,i)=>{
          const from=(page-1)*Conf.perPage+1;
          const to=Math.min(nb,page*Conf.perPage);
          if (i+1>=from && i+1<=to) {
            return <ResidencePassee key={residence.id} residence={residence}/>;
          }
          return null;
        })}
      </Grid>
      <Grid item xs={12} md={4}>
        <div className="residences-a-venir">
          {residencesAvenir.map((residence,i)=><ResidenceAvenir key={residence.id} residence={residence}/>)}
        </div>
      </Grid>
    </Grid>
    <Mod url={'residences'} refresh={refetch}/>
  </div>);
}
export default Residences;
