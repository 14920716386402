import React, {useContext} from "react";
import './CartoucheInfos.scss';
import { SettingsContext } from 'Providers/SettingsProvider';
import Animation from 'components/Animation';
import Image from 'components/Image';
import Utils from 'utils/Utils';

function CartoucheInfos({data}) {
  const { t, translate } = useContext(SettingsContext);
  const renderItem=(item,i)=><React.Fragment key={i}>
      {item.value!=='' && item.key && <div className="cartouche-infos-label">{t(item.key)}</div>}
      {item.value!=='' && item.value && item.key_tr && <div className="cartouche-infos-label">{item.key_tr}</div>}
      {item.value!=='' && item.value && <div className={"cartouche-infos-value size-"+(item.size ? item.size : 'big')+(item.grow ? ' grow' : '')}>{item.value}</div>}
      {item.animation!=='' && item.animation && <div className="cartouche-infos-animation"><Animation frames={item.animation.frames} reset={item.reset}/></div>}
      {item.image && <div className="cartouche-infos-image">
        <Image
        credits={item.credits}
        file={item.image}
        thumbnailKey={item.nocrop ? "16-9" : "16-9-crop"}
        background={item.visuel_background}
        ratio={0.5}
        align={'left'}/>
      </div>}
      {item.text!=='' && item.text && <div className="cartouche-infos-texte select">
        {Utils.parseWithLinks(translate(item,'text'))}
      </div>}
  </React.Fragment>;
  let classes="cartouche-infos";
  return data ? <div className={classes}>
      {data.map((item,i)=>{
          return renderItem(item,i);
      })}

  </div> : '';
}

export default CartoucheInfos;
