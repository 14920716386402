import React from "react";
import Utils from 'utils/Utils';
import './Residence.scss'
import CartoucheInfos from 'components/CartoucheInfos';
import SlugLink from 'components/Router/SlugLink';

function ResidenceAvenir({residence}) {
  const dataResidence=[
    {key:'residence-a-venir',value:residence.title,size:'medium'},
    Utils.renderArtistes(residence,false,false,'medium'),
    {key:null,value:Utils.dates(residence),size:'small'},
  ];
  return <div className="residence-bloc">
    <SlugLink item={residence}><CartoucheInfos data={dataResidence}/></SlugLink>
  </div>;
}
export default ResidenceAvenir;
