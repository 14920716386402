import React, {useContext} from "react";
import './AcProject.scss';
import { SettingsContext } from 'Providers/SettingsProvider';

function AcProject({acProject}) {
  const { translate } = useContext(SettingsContext);
  return <span className='ac-project'>{translate(acProject,'title')}</span>;
}

export default AcProject;
