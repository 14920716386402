import React from "react";
import './Footer.scss';
import FooterClone from 'components/Footer/FooterClone';
import FooterInner from 'components/Footer/FooterInner';

function Footer() {
  return <><div className="footer" id="footer">
      <FooterInner/>
  </div>
  <FooterClone/>
  </>;
}

export default Footer;
