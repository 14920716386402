import React, { useContext, useRef, useEffect } from "react";
import './Pagination.scss';
import { SettingsContext } from 'Providers/SettingsProvider';

function Pagination({nb,perPage,onPageChange,typeSingulier,typePluriel,paginationId,delta=0,mini=false}) {
  const { t,appState,setAppState } = useContext(SettingsContext);
  const nbPages=parseInt(Math.ceil(nb/perPage));
  const el=useRef(null);
  const paginationState=appState.paginations[paginationId];
  useEffect(()=>{
    if (el.current) {
      if (paginationState && paginationState.touched>0) setTimeout(()=>window.scrollTo({
        left:0,
        top:el.current.offsetTop-60-delta,
        behavior:'smooth',
      }),100);
    }
  },[paginationState,delta]);
  useEffect(()=>{
    const touched=0;
    setAppState((state)=>{
      const page = (state.paginations[paginationId] && state.paginations[paginationId].page) || 1;
      return {...state,paginations:{ ...state.paginations, [paginationId]:{page,touched}}}
    });
  },[setAppState,paginationId]);
  const handleChange=(page)=>{
    const touched = (appState.paginations[paginationId] && appState.paginations[paginationId].touched+1) || 0;
    setAppState((state)=>{return {...state,paginations:{ ...state.paginations, [paginationId]:{page,touched}}}});
  }
  const page = (appState.paginations[paginationId] && appState.paginations[paginationId].page) || 1;
  return <>
  <div className="pagination-anchor" ref={el}></div>
  <div className={"pagination"+(mini ? ' mini' :'')+(nbPages>1 ? ' sticky' :'')}>
      {nb===1 && <>{nb} {t(typeSingulier)}</>}
      {nb>1 && <>{nb} {t(typePluriel)}</>}
      {nbPages>1 && <div className="pagination-right">
          <span className="chevron" onClick={()=>handleChange(1)}>{'<<'}</span>
          <span className="chevron" onClick={()=>handleChange(Math.max(1,page-1))}>{'<'}</span>
          <span className="page">{t('page')} {page}/{nbPages}</span>
          <span className="chevron" onClick={()=>handleChange(Math.min(nbPages,page+1))}>{'>'}</span>
          <span className="chevron" onClick={()=>handleChange(nbPages)}>{'>>'}</span>
      </div>}
  </div>
  </>;
}

export default Pagination;
