import React from "react";
import Utils from 'utils/Utils';
import './Exposition.scss'
import CartoucheInfos from 'components/CartoucheInfos';
import SlugLink from 'components/Router/SlugLink';

function ExpoPassee({expo}) {
  const dataExpo=[
    {key:'exposition-passee',value:expo.title},
    Utils.renderArtistes(expo,false,false),
    {key:null,value:Utils.dates(expo),size:'small'},
  ];
  return <SlugLink item={expo} tile={true}><div className="expo-bloc">
    <CartoucheInfos data={dataExpo}/>
  </div></SlugLink>;
}
export default ExpoPassee;
