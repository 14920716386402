import React, { useContext, useEffect } from "react";
import { useQuery } from '@apollo/client';
import './Rubrique.scss'
import Queries from 'utils/Queries';
import { SettingsContext } from 'Providers/SettingsProvider';
import Grid from '@material-ui/core/Grid';
import SlugLink from 'components/Router/SlugLink';
import PageInner from 'components/Page/PageInner';
import { useParams } from "react-router-dom";
import Mod from 'components/Mod';

function Rubrique() {
  const { slug }=useParams();
  const { translate, setCurrentRub, updateLoadState, fetchedList } = useContext(SettingsContext);
  const { refetch, networkStatus, data } = useQuery(Queries.rubrique,{
    variables: { slug },
  });
  const uuid='rubrique/'+slug;
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const rubrique=data ? data.rubriques[0] : null;
  useEffect(()=>{
    console.log(rubrique);
    if (rubrique) {
      setCurrentRub(rubrique.id);
    } else {
      setCurrentRub(null);
    }
    return ()=>setCurrentRub(null);
  },[rubrique,setCurrentRub]);
  return rubrique ? <div className='rubrique'>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className="spacer5"></div>
        {rubrique.typr==='menu' && <>
          <div className="rubrique-title-small">
            {translate(rubrique,'title')}
          </div>
          {rubrique.pages.map((page)=><div key={page.id} className="page-item">
            <SlugLink item={page}>{translate(page,'title')}</SlugLink>
          </div>)}
        </>}
        {rubrique.typr==='article' && rubrique.pages.map((page)=><PageInner key={page.id} page={page} type={false} refresh={refetch}/>)}
      </Grid>
    </Grid>
    <Mod url={'pages'} refresh={refetch}/>
  </div> : '';
}
export default Rubrique;
