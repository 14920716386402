import React, { useContext, useEffect } from "react";
import './Editions.scss'
import EditionBloc from 'components/Edition/EditionBloc';
import Grid from '@material-ui/core/Grid';
import { useQuery } from '@apollo/client';
import Queries from 'utils/Queries';
import { SettingsContext } from 'Providers/SettingsProvider';
import Conf from 'utils/Conf';
import Pagination from 'components/Pagination';
import {ReactComponent as Carre} from 'imgs/Carre.svg';
import Mod from 'components/Mod';

function Editions() {
  const { t, filter, setFilter, updateLoadState, fetchedList, appState, setAppState } = useContext(SettingsContext);
  const { refetch, networkStatus, data } = useQuery(Queries.editions);
  const uuid='editions';
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const editions= data ? data.editions : [];
  let types= data ? data.editions_types : [];
  const nb=editions ? editions.length : 0;
  const touch=()=>{
    const page = 1;
    const touched=(appState.paginations[uuid] && appState.paginations[uuid].touched+1) || 1;
    setAppState((state)=>{return { ...state, paginations: { ...state.paginations, [uuid]:{page,touched} } }});
  }
  const handleTypeChange=(id)=>{
    const {editions_types:filterTypes,...otherFilters}=filter;
    const newTypes=[...filterTypes];
    const idx=newTypes.indexOf(id);
    if (idx!==-1) newTypes.splice(idx,1);
    else newTypes.push(id);
    setFilter({editions_types:newTypes,...otherFilters});
    touch();
  }

  const page = (appState.paginations[uuid] && appState.paginations[uuid].page) || 1;
  useEffect(()=>{
    document.title=t('editions')
  },[t]);
  return (<div className="editions">
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        {nb>0 && <Pagination paginationId={uuid} page={page} nb={nb} typeSingulier='edition' typePluriel='editions' perPage={Conf.perPage}/>}
        {editions.filter((edition)=>filter.editions_types.length===0 || filter.editions_types.indexOf(edition.type.id)!==-1).map((edition,i)=>{
          const from=(page-1)*Conf.perPage+1;
          const to=Math.min(nb,page*Conf.perPage);
          if (i+1>=from && i+1<=to) {
            return <EditionBloc key={edition.id} edition={edition}/>
          }
          return null;
        })}
      </Grid>
      <Grid item xs={12} sm={4} className="cartouche-infos">
        <div className="editions-filtres">
          <div className="cartouche-infos-label">{t('filtres')}</div>
          {types.map((ty,i)=>{
            let classes='filter-type';
            if (filter.editions_types.indexOf(ty.id)!==-1) classes+=" active";
            return <div key={i} className={classes} onClick={()=>handleTypeChange(ty.id)}><Carre/> {ty.nom}</div>
          })}
        </div>
      </Grid>
    </Grid>
    <Mod url={'editions'} refresh={refetch}/>
  </div>);
}
export default Editions;
