import React, {useContext} from "react";
import {
  Link
} from "react-router-dom";
import { SettingsContext } from 'Providers/SettingsProvider';

const parseType=(type)=>{
  if (type==='ac_project') return 'propositions_eac';
  if (type==='ac_instances') return 'realisations_eac';
  return type;
}

const SlugLink = React.forwardRef(({ item, className='', tile=false, children, forwardedRef }, ref) => {
  const { removeScrollY } = useContext(SettingsContext);
  const slug=item ? ( item.slugs && item.slugs[0] ? item.slugs[0].slug : item.id ) : '';
  let type=item ? item.__typename : '';
  if (type==='rdvs' && item.agenda==='blog') type='blog';
  if (type==='expositions' && item.type==='horslesmurs') type='expos';
  const link='/'+parseType(type)+'/'+slug;
  return <Link to={link} className={className+(tile?' tile-link':'')} ref={ref || forwardedRef} onClick={()=>removeScrollY(link)}>{children}</Link>;
});

export default SlugLink;
