import React, { useContext, useEffect, useState } from "react";
import './MenuLeft.scss';
import { SettingsContext } from 'Providers/SettingsProvider';
import {
  Link,
  useLocation,
} from "react-router-dom";
import Queries from 'utils/Queries';
import { useQuery } from '@apollo/client';
import SlugLink from 'components/Router/SlugLink';
import Utils from 'utils/Utils';
import { DateTime } from "luxon";

function MenuLeft() {
  const { t, translate, currentRub, updateLoadState, fetchedList, removeScrollY, appState } = useContext(SettingsContext);
  const { refetch, networkStatus, data } = useQuery(Queries.rubriques,{
    variables: {
      hier:DateTime.now().minus({ days: 1 }).toISODate(),
    },
  });
  const uuid='menuLeft';
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const [ open,setOpen ] = useState(null);
  useEffect(()=>{
    if (appState.refresh>0) refetch();
  },[appState.refresh,refetch]);
  const rubriques=data ? data.rubriques : [];
  const pages=data ? data.pages : [];
  const expos=data ? data.expositions : [];
  const tab=[
    {
        titre: t('expositions'),
        type: expos.length>0 ? 'expositions-menu' : 'expositions',
        link: expos.length>0 ? false : true,
        id:expos.length>0 ? 'expositions-menu' : 'expositions',
        pages:[],
        links:(expos.length>0 ? [
          {
            titre:t('centredart'),
            type:'expositions',
            link:true,
            id:'expositions',
          },
          {
            titre:t('horslesmurs'),
            type:'expos',
            link:true,
            id:'expos',
          },
        ]:[]),
    },
    {
        titre: t('rendez-vous'),
        type: 'rdvs',
        link: true,
        id:'rdvs',
    },
    {
        titre: t('actualites'),
        type: 'blog',
        link: true,
        id:'blog',
    },
    {
        titre: t('education-artistique'),
        type: 'education-artistique',
        link: true,
        id:'education-artistique',
    },
    {
        titre: t('residences'),
        type: 'residences',
        link: true,
        id:'residences',
    },
    {
        titre: t('editions'),
        type: 'editions',
        link: true,
        id:'editions',
    },
    ...rubriques.map((r)=>{return {
        titre:translate(r,'title'),
        type:r.slugs[0].slug,
        link:false,
        pages: r.pages,
        links:[],
        id:r.id,
        item:r,
    }}),
    ...pages.map((p)=>{
      //console.log(p);
      return {
        titre:translate(p,'title'),
        type:'pages/'+p.slugs[0].slug,
        link:true,
        id:p.id,
    }})
  ];

  let classes='menu-left';
  const {pathname}=useLocation();
  const isActive=(item)=>{
    if (item.id===currentRub) return true;
    if (pathname.startsWith(`/${item.type}/`) || pathname===`/${item.type}`) return true;
    if(item.type==='education-artistique') {
      if (pathname.startsWith('/ac_')) return true;
    }
    if(item.type==='expos' && (pathname.startsWith(`/expositions/`) || pathname===`/expositions`)) return true;
    if (item.links) {
      for (var i = 0; i < item.links.length; i++) {
        const link=item.links[i];
        if (pathname.startsWith(`/${link.type}/`) || pathname===`/${link.type}`) return true;
      }
    }
    if (item.pages) {
      for (var j = 0; j < item.pages.length; j++) {
        const page=item.pages[j];
        if (pathname.startsWith(`/${page.type}/`) || pathname===`/${page.type}`) return true;
      }
    }
  }
  return <div className={classes}>
          {tab.map((item,i)=>{
            if (item.link===true) return <Link onClick={()=>removeScrollY("/"+item.type)} key={item.type} to={"/"+item.type}><div className={'menu-left-item'+(isActive(item) ? " active" : "")}>{item.titre}</div></Link>;
            return <React.Fragment key={item.id}>
            <div className={'menu-left-item'+(isActive(item) ? " active" : "")} onClick={()=>setOpen(open=>open===item.id ? null : item.id)}>
              {item.pages.length+item.links.length===0 ?
                (item.item ?
                  <SlugLink key={item.id} item={item.item}><div className={isActive(item) ? "active" : ""}>{item.titre}</div></SlugLink>
                  :
                  <Link onClick={()=>removeScrollY("/"+item.type)} key={item.type} to={"/"+item.type}><div>{item.titre}</div></Link>
                )
                :
                <div className={"menu-left-item-btn"+(currentRub===item.id ? " active" : "")}>
                  <div className="text">{item.titre}</div>
                </div>
              }
              </div>
              {open===item.id && item.pages.length>0 && <div className="menu-left-item-pages">
                {item.pages.map((page,i)=>{
                  let pageClasses='menu-left-item';
                  if (Utils.pathPageMatch(pathname,page)) pageClasses+=' active';
                  return <SlugLink key={page.id} item={page}><div className={pageClasses}>{page.title}</div></SlugLink>;
                })}
              </div>}
              {open===item.id && item.links.length>0 && <div className="menu-left-item-pages">
                {item.links.map((link,i)=>{
                  let linkClasses='menu-left-item';
                  if (pathname.startsWith(`/${link.type}/`) || pathname===`/${link.type}`) linkClasses+=' active';
                  return <Link onClick={()=>removeScrollY("/"+link.type)} key={link.id} to={"/"+link.type}><div className={linkClasses}>{link.titre}</div></Link>;;
                })}
              </div>}
              </React.Fragment>
          })}
      </div>;
}

export default MenuLeft;
