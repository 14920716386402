import React, {useContext, useEffect, useState} from "react";
import { useQuery } from '@apollo/client';
import './Ac.scss'
import { SettingsContext } from 'Providers/SettingsProvider';
import Queries from 'utils/Queries';
import Grid from '@material-ui/core/Grid';
import RdvTile from 'components/Rdv/RdvTile';
import Utils from 'utils/Utils';
import Image from 'components/Image';
import Biographie from 'components/Artiste/Biographie';
import { useParams } from "react-router-dom";
import Mod from 'components/Mod';
import Ressources from 'components/Ressources';
import Partenaires from 'components/Partenaires';
import ExpoMini from 'components/Exposition/ExpoMini';
import Share from 'components/Share';

import SwiperCore, { Navigation, Autoplay, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
SwiperCore.use([Navigation, Autoplay, A11y]);

function AcInstance({id}) {
  const [ acInstance, setAcInstance ] = useState(null);
  const { slug }=useParams();
  const { translate, t, updateLoadState, fetchedList } = useContext(SettingsContext);
  const { refetch, networkStatus, data } = useQuery(Queries.ac_instance,{
    variables: { slug },
  });
  const uuid='ac_instance/'+slug;
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  useEffect(()=>{
    if (data) {
        setAcInstance(data.ac_instances[0]);
    }
  },[data]);
  useEffect(()=>{
    if (acInstance && acInstance.title) document.title=acInstance.title
  },[acInstance]);
  return acInstance ? <div className="ac-instance">
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <div className="spacer5"></div>
        <div className="ac-instance-visuel">
          <Image credits file={acInstance.visuel} thumbnailKey={"carre-small-nocrop"} background={acInstance.visuel_background || 'white'}/>
        </div>
        {acInstance.expositions.length>0 && <div className="cartouche-infos">
          <div className="type-billet">{t('restitution')}</div>
          {acInstance.expositions.map(({expositions_id:expo})=><ExpoMini key={expo.id} expo={expo} mini={true} resume={true} lieu={true}/>)}
          <div className="spacer"/>
        </div>}
        <div className="spacer"/>
        {acInstance.rdvs.length>0 && <div className="cartouche-infos">
          <div className="type-billet">{t('rendez-vous')}</div>
          {acInstance.rdvs.filter((rdv)=>rdv.rdvs_id).map((rdv,i)=><RdvTile key={rdv.rdvs_id.id} rdv={rdv.rdvs_id} size="mini"/>)}
        </div>}
      </Grid>
      <Grid item xs={12} sm={8}>
        <div className="spacer5"></div>
        <div className="ac-instance-type">
          {acInstance.ac_projects && acInstance.ac_projects.map((acProject,i)=>{
            return <React.Fragment key={acProject.ac_project_id.id}>{i>0 && ', '}<span>{translate(acProject.ac_project_id,'title')}</span></React.Fragment>})
          }
        </div>
        <div className="ac-instance-titre">{acInstance.title}</div>
        {acInstance.artistes.length>0 && <div className="ac-instance-artiste">{Utils.renderArtistes(acInstance,true,false).value}</div>}
        <div className="ac-instance-texte select">
          {Utils.parseWithLinks(translate(acInstance,'text'))}
        </div>
        { acInstance.diaporama && <div className="ac-instance-diaporama">
            <Swiper
            speed={800}
            loop
            autoplay={{
              delay: 3000,
            }}
            navigation={acInstance.diaporama.length>1}
            enabled={acInstance.diaporama.length>1}
            >
              {acInstance.diaporama.map((diap,i)=>{
                  const img=diap.directus_files_id;
                  return <SwiperSlide key={img.id}>
                    <Image file={img} thumbnailKey={"16-9"} ratio={0.5625}/>
                  </SwiperSlide>;
              })}
            </Swiper>
        </div>}
        <Share/>
        {acInstance.artistes.map((artiste)=>artiste.artistes_id && artiste.artistes_id.bio ? <Biographie key={artiste.artistes_id.id} artiste={artiste.artistes_id}/> : null)}
        <Ressources item={acInstance}/>
        <Partenaires item={acInstance}/>
      </Grid>
      <Grid item xs={12} md={4}>
      </Grid>
    </Grid>
    <Mod url={acInstance.__typename+'/'+acInstance.id} refresh={refetch}/>
  </div> : '';
}
export default AcInstance;
