import React from "react";
import Utils from 'utils/Utils';
import './Residence.scss'
import CartoucheInfos from 'components/CartoucheInfos';
import SlugLink from 'components/Router/SlugLink';

function ResidencePassee({residence}) {
  const dataResidence=[
    {key:'residence-passee',value:residence.title},
    Utils.renderArtistes(residence,false,false),
    {key:null,value:Utils.dates(residence)},
  ];
  return <SlugLink item={residence} tile={true}><div className="residence-bloc">
    <CartoucheInfos data={dataResidence}/>
  </div></SlugLink>;
}
export default ResidencePassee;
