import React, { useState, useEffect, useContext } from "react";
import './Mod.scss';
import {IconButton} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import Conf from 'utils/Conf';
import { SettingsContext } from 'Providers/SettingsProvider';

function Mod({url,refresh=()=>console.log('default'),dy=0}) {
  const { appState, setAppState, settingsRefetch } = useContext(SettingsContext);
  const [ presses, setPresses ] = useState(false);
  const setTimer = useState(false)[1];
  const handleRefresh=()=>{
    refresh();
    settingsRefetch();
  }
  useEffect(()=>{
    const handleKeyDown=(e)=>{
      if(e.keyCode===17){
        setPresses((presses)=>{
          return presses+1;
        });
        setTimer((timer)=>{
          clearInterval(timer);
          return setTimeout(()=>{
            setPresses(0);
          },300);
        });
      }
    }
    window.addEventListener('keydown',handleKeyDown);
    return ()=>{
      window.removeEventListener('keydown',handleKeyDown);
    }
  },[setPresses,setTimer]);
  useEffect(()=>{
    if(presses>2) {
      setAppState((state)=>{return {...state,modOn:!Boolean(state.modOn)}});
      setPresses(0);
    }
  },[presses,setPresses,setAppState]);
  const link='/admin/content/'+url;
  const fullLink=Conf.apiUrl+link;
  const openWindow=()=>{
    if (appState.editWindows[link] && !appState.editWindows[link].closed) {
      appState.editWindows[link].focus();
    }
    else setAppState((state)=>{return{...state, editWindows:{...state.editWindows,[link]:window.open(fullLink,'directus'+link)}}});
  }
  return appState.modOn ? <div className="mod">
  <div className="mod-inner" style={{transform:`translate3d(0,${dy}px,0)`}}>
    <IconButton
      size="small"
      color="inherit"
      style={{backgroundColor:"#FFF", boxShadow:"0 0 5px #0002"}}
      onClick={openWindow}
    >
      <EditIcon/>
    </IconButton>
    <span>&nbsp;</span>
    <IconButton
      size="small"
      color="inherit"
      style={{backgroundColor:"#FFF", boxShadow:"0 0 5px #0002"}}
      onClick={handleRefresh}
    >
      <RefreshIcon/>
    </IconButton>
  </div>
  </div> : null;
}

export default Mod;
