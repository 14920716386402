import React, { useContext, useEffect } from "react";
import { useQuery } from '@apollo/client';
import './EacDocs.scss'
import Queries from 'utils/Queries';
import { SettingsContext } from 'Providers/SettingsProvider';
import Grid from '@material-ui/core/Grid';
import Conf from 'utils/Conf';
import {ReactComponent as PictoDownload} from 'imgs/download.svg';
import _ from 'lodash';
import Mod from 'components/Mod';
import Pagination from 'components/Pagination';

const perPage=15;

function EacDocs() {
  const { t, updateLoadState, fetchedList, appState } = useContext(SettingsContext);
  const { refetch, networkStatus, data } = useQuery(Queries.eac_docs);
  const uuid='ressources';
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const ressources=data ? data.eac_docs : [];
  const ressourcesTypes=data ? data.eac_docs_types : [];
  const types=_.groupBy(ressources,(o)=>o.type ? o.type.id : 'autres');
  useEffect(()=>{
    document.title=t('ac-ressources')
  },[t]);
  return ressources ? <div className='eac-docs'>
    <div className="spacer"></div>
    <div className="eac-ressource-titre">
      {t('ac-ressources')}
    </div>
    <Grid container spacing={2}>
      {ressourcesTypes.map((type)=>{
        const nb=types[type.id].length;
        const uuid='eac_docs-'+type.id;
        const page = (appState.paginations[uuid] && appState.paginations[uuid].page) || 1;
        return <Grid key={type.id} item xs={12}>
        <div className="eac-ressource-type">
          {nb>0 && <Pagination mini paginationId={uuid} page={page} nb={nb} typeSingulier={'document'} typePluriel={'documents'} perPage={perPage}  delta={-143}/>}
          <div className="eac-ressource-type-titre">
            {type.titre}
          </div>
          {types[type.id].sort((a,b)=>type.tri==='alpha' ? a.title.localeCompare(b.title) : a.sort-b.sort).map((doc,i)=>{
            const from=(page-1)*perPage+1;
            const to=Math.min(nb,page*perPage);
            if (i+1>=from && i+1<=to) {
              return <div key={doc.id} className="eac-ressource-doc">
              {Boolean(doc.document) && <div className="eac-ressource-doc-titre"><a href={Conf.apiUrl+'/assets/'+doc.document.id+'?download'} target="_blank" rel="noreferrer">{doc.title} <span className="icon-download"><PictoDownload/></span></a></div>}
              {!Boolean(doc.document) && <div className="eac-ressource-doc-titre">{doc.title}</div>}
            </div>;
          } else return null;})}
        </div>
      </Grid>;
    })}
    </Grid>
    <Mod url={'eac_docs'} refresh={refetch}/>
  </div> : '';
}
export default EacDocs;
