import React from "react";
import './Ac.scss'
import SlugLink from 'components/Router/SlugLink';
import Utils from 'utils/Utils';
import Image from 'components/Image';

function AcInstanceTile({acInstance,size='big',className=null}) {
  return acInstance ? <SlugLink item={acInstance} tile={true}>
    { size==='big' && <div className="ac-instance-tile">
      <div className="ac-instance-tile-head">
        <div className="ac-instance-tile-type">{Utils.renderAcProjects(acInstance,'x-small').value}</div>
        <div className="ac-instance-tile-titre">
          {acInstance.title}
          <div>{Utils.renderArtistes(acInstance,false,false).value}</div>
        </div>
      </div>
      <Image file={acInstance.visuel} thumbnailKey={"carre-small"} background={acInstance.visuel_background} crop={acInstance.crop}/>
    </div>}
    { size==='mini' && <div className={"ac-instance-tile"+(className ? ' '+className : '')}>
      <div className="ac-instance-tile-type">{Utils.renderAcProjects(acInstance,'x-small').value}</div>
      <div className="ac-instance-tile-mini-titre">
        {acInstance.title}
        <div>{Utils.renderArtistes(acInstance,false,false).value}</div>
      </div>
    </div>}
  </SlugLink> : '';
}
export default AcInstanceTile;
