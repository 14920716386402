import React from "react";
import Artiste from 'components/Artiste';
import AcProject from 'components/AcProject';
import { DateTime } from "luxon";
import parse, { domToReact,attributesToProps } from 'html-react-parser';
import { Link } from 'react-router-dom';
import { Email } from "react-obfuscate-email";

const Utils={
      nbsp:(text)=>{
        let res=text+'';
        res=res.replace(/[ ]+!/gm,"&nbsp;!");
        res=res.replace(/[ ]+\?/gm,"&nbsp;?");
        res=res.replace(/[ ]+:/gm,"&nbsp;:");
        res=res.replace(/[ ]+;/gm,"&nbsp;;");
        return res;
      },
      parseWithLinks: (text)=>{
      if (text) {
        let res=Utils.nbsp(text);
        const options = {
          replace: (domNode) => {
            let { parent, type, data, name, attribs, children }=domNode;
            if ((!parent || parent.name !== 'a') && type==='text') {
              const boundary='##-------##';
              let res=data.replace(/http:\/\/\S*|https:\/\/\S*|www\.\S*|\S+@\S+/gm,boundary+'$&'+boundary);
              return <>{res.split(boundary).map((t,i)=>{
                if (t.startsWith('http://') || t.startsWith('https://')) return <React.Fragment key={i}>
                  <a target="_blank" rel="noreferrer" href={t}>
                    {t}
                  </a>
                </React.Fragment>;
                if (t.startsWith('www.')) return <React.Fragment key={i}>
                  <a target="_blank" rel="noreferrer" href={'https://'+t}>
                    {t}
                  </a>
                </React.Fragment>;
                if (t.match(/\S+@\S+/)) return <React.Fragment key={i}>
                  <Email email={t}>{t.replace('@','(a)')}</Email>
                </React.Fragment>;
                return <React.Fragment key={i}>{t}</React.Fragment>;
              })}</>;
            }
            if (
              name === 'a'
              && attribs.href
              && attribs.href.indexOf('mailto:')!==-1
            ) {
              const mail=attribs.href.replace('mailto:','');
              return <Email email={mail}>{domToReact(children)}</Email>;
            }
            if (
              name === 'a'
              && attribs.href
              && attribs.href.indexOf('://')===-1
              && !attribs.href.startsWith('www.')
            ) {
              return <Link to={attribs.href}>{domToReact(children)}</Link>;
            }
            if (
              name === 'a'
              && attribs.href
              && attribs.href.startsWith('www.')
            ) {
              const props = attributesToProps({...attribs,href:'https://'+attribs.href});
              return <a {...props}>{domToReact(children)}</a>;
            }
          }
        };
        return parse(res, options);
      }
      return '';
    },
    shuffle: (tab)=> {
        let array=[...tab];
        for(let i = array.length - 1; i > 0; i--){
        const j = Math.floor(Math.random() * i)
        const temp = array[i]
        array[i] = array[j]
        array[j] = temp
      }
      return array;
    },
    translate: (item,lang,key) => {
        let res=item ? item[key] : '';
        if (item.translations) {
            const tr=item.translations.find((o)=>o.languages_code && o.languages_code.code===lang);
            if (tr && tr[key]) res=tr[key];
        }
        return res;
    },
    accentsTidy:function(s) {
        var map = [
            ["[àáâãäåāăąǎǟǡǻȁȃȧ]", "a"],
            ["[æ]", "ae"],
            ["[ƀƃɓ]", "b"],
            ["[çćĉċčƈȼɕ]", "c"],
            ["[ďđƌȡɖɗ]", "d"],
            ["[èéêëēĕėęěȅȇȩɇ]", "e"],
            ["[ƒ]", "f"],
            ["[ĝğġģǥǧǵɠ]", "g"],
            ["[ĥħȟɦ]", "h"],
            ["[ìíîïĩīĭįǐȉȋɨ]", "i"],
            ["[ĵǰɉʝ]", "j"],
            ["[ķƙǩ]", "k"],
            ["[ĺļľŀłƚȴɫɬɭ]", "l"],
            ["[ɱ]", "m"],
            ["[ñńņňƞǹȵɲɳ]", "n"],
            ["[òóôõöøōŏőơǒǫǭǿȍȏȫȭȯȱ]", "o"],
            ["[œ]", "oe"],
            ["[ƥ]", "p"],
            ["[ɋʠ]", "q"],
            ["[ŕŗřȑȓɍɼɽɾ]", "r"],
            ["[śŝşšșȿʂ]", "s"],
            ["[ţťŧƫƭțȶʈ]", "t"],
            ["[ùúûüũūŭůűųưǔǖǘǚǜȕȗ]", "u"],
            ["[ʋ]", "v"],
            ["[ŵ]", "w"],
            ["[ýÿŷƴȳɏ]", "y"],
            ["[źżžƶǅȥɀʐʑ]", "z"],
        ];
        for (var i=0; i<map.length; ++i) {
            s = s.replace(new RegExp(map[i][0], "gi"), ((j)=> {
                return (match) => {
                    if (match.toUpperCase() === match) {
                        return map[j][1].toUpperCase();
                    } else {
                        return map[j][1];
                    }
                }
            })(i));
        }
        return s;
    },
    accentsTidyLw:(s)=>{
        return Utils.accentsTidy(s).toLowerCase();
    },
    type2icon:(t)=>{
        if (t==='images') return 'image';
        if (t==='videos') return 'video';
        if (t==='multimedias') return 'multimedia';
        if (t==='textes') return 'texte';
        if (t==='audios') return 'audio';
        if (t==='groupes') return 'groupe';
    },
    //Cartouches
    renderM2M:(item,key,singular=key)=>item[key] && item[key].length>0 ? item[key].map((o)=>o[singular+'_id']).filter((o)=>Boolean(o)) : [],
    renderArtistes:(item,active=false,key=true,size='big')=>{
        const artistes=Utils.renderM2M(item,'artistes');
        return {
          key:key ? (artistes.length>1 ? 'artistes' : 'artiste') : null ,
          value:artistes.length>0 ? artistes.map((artiste,i)=><React.Fragment key={artiste.id}>{i>0 && ', '}<Artiste artiste={artiste} active={active}/></React.Fragment>) : '',
          size
        };
    },
    renderAcProjects:(item,size='x-small')=>{
      const acProjects=Utils.renderM2M(item,'ac_projects','ac_project');
      return {
        value:acProjects.length>0 ? acProjects.map((acProject,i)=><React.Fragment key={acProject.id}>{i>0 && ', '}<AcProject acProject={acProject}/></React.Fragment>) : '',
        size
      };
    },
    dates:(item)=>{
        if (item.date_from && item.date_to) return Utils.date(item.date_from)+'-'+Utils.date(item.date_to);
        if (item.date_from) return Utils.date(item.date_from);
        if (item.date) return Utils.date(item.date);
        return '';
    },
    date:(d)=>{
        return DateTime.fromISO(d).toFormat('dd|LL|yy');
    },
    pathPageMatch:(pathname,page)=>{
        let test=false;
        if (pathname===`/pages/${page.id}`) test=true;
        page.slugs.forEach((s)=>{
            if (pathname===`/pages/${s.slug}`) test=true;
        });
        return test;
    }
}
export default Utils;
