import React, {useContext} from "react";
import './Partenaires.scss'
import { SettingsContext } from 'Providers/SettingsProvider';
import Conf from 'utils/Conf';

function Partenaires({item}) {
  const { t } = useContext(SettingsContext);
  const { partners }= item || {};
  const partnerInner=(partner)=><div>
    <div>{partner.name}</div>
    {partner.logo && <img src={`${Conf.apiUrl}/assets/${partner.logo.id}?key=partner`} alt={partner.name}/>}
  </div>;
  return partners && partners.length>0 ? <div className="partenaires">
    <div className="partenaires-titre">{t('avec')}</div>
    <div className="partenaires-nom">{t('partenaires')}</div>
    <div className="partenaires-list">
      {partners && partners.map((doc,i)=>{
        const partner=doc.partner_id;
        return partner ? <div key={partner.id} className="partenaire">
          {partner.url ? <a href={partner.url} target="_blank" rel="noreferrer">{partnerInner(partner)}</a> : partnerInner(partner)}
        </div> : ''}
      )}
      </div>
    </div> : '';
}
export default Partenaires;
