import React, {useContext} from "react";
import './Ressources.scss'
import { SettingsContext } from 'Providers/SettingsProvider';
import Conf from 'utils/Conf';
import filesize from 'filesize';
import mime from 'mime-types';
import {ReactComponent as PictoDownload} from 'imgs/download.svg';

function Ressources({item}) {
  const { t } = useContext(SettingsContext);
  const { documents,ressources }= item || {};
  const docs= documents || ressources;
  return docs && docs.length>0 ? <div className="ressources">
    <div className="ressources-titre">{t('a-telecharger')}</div>
    <div className="ressources-nom">{t('ressources')}</div>
    <div className="ressources-documents">
      {docs && docs.map((doc,i)=>{
        const docId=doc.directus_files_id.id;
        const d=doc.directus_files_id;
        const url=`${Conf.apiUrl}/assets/${docId}`;
        return d ? <div className="document-container" key={docId}>
              {d.title} ({mime.extension(d.type)}) - {filesize(d.filesize)} <a href={url} target="_blank" rel="noreferrer"><span className="icon-download"><PictoDownload/></span></a>
          </div> : ''}
      )}
      </div>
    </div> : '';
}
export default Ressources;
