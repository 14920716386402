import React, { useState, createContext, useMemo, useEffect, useCallback } from "react";
import { useQuery } from '@apollo/client';
import Utils from 'utils/Utils';
import Queries from 'utils/Queries';

export const SettingsContext = createContext({});

const doUpdateLoadState=(key,networkStatus,setLoadlist, setFetchedList)=>{
    setFetchedList((list)=>{
        if (!Array.isArray(list)) list=[];
        let idx=list.indexOf(key);
        if (idx===-1) return [...list,key];
        return list;
    });
    if (networkStatus===1) setLoadlist((list)=>{
        if (!Array.isArray(list)) list=[];
        let idx=list.indexOf(key);
        if (idx===-1) return [...list,key];
        return list;
    });
    if (networkStatus!==1) setTimeout(()=>setLoadlist((list)=>{
        if (!Array.isArray(list)) list=[];
        let idx=list.indexOf(key);
        if (idx!==-1) {
            let res=list.reduce((acc,l,i)=>(i!==idx) ? [...acc,l]: acc ,[]);
            return res;
        }
        return list;
    }),500);
}

const SettingsProvider = ({children})=>{
    const [ appState, setAppState ] = useState({
      expositions:{},
      paginations:{},
      searchBase:{},
      editWindows:{},
      refresh:0,
    });
    const [ filter, setFilter ] = useState({search:'', types:[], editions_types:[], expos:[], archives:false});
    const [ lang, setLang ] = useState('fr');
    const [ tick, setTick ] = useState(0);
    const [ currentRub, setCurrentRub ] = useState(null);
    const [ loadlist, setLoadlist ] = useState(null);
    const [ fetchedList, setFetchedList ] = useState([]);
    const { networkStatus,data,refetch } = useQuery(Queries.settings);
    const updateLoadState=useMemo(()=>
      (key,networkStatus)=>doUpdateLoadState(key,networkStatus,setLoadlist,setFetchedList),
      [setLoadlist,setFetchedList]
    );
    const uuid='settings';
    useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
    useEffect(()=>{
      if(fetchedList.indexOf(uuid)===-1){
        console.log('fetch',uuid);
        refetch();
      }
    },[refetch,fetchedList,uuid]);
    const trads=useMemo(()=>data ? data.interface.reduce((acc,item,i)=>{
        const key=item.key;
        const value=item.value;
        const text=item.text;
        const translations=item.translations.reduce((accTr,tr,j)=>{
            const lang=tr.languages_code.code;
            const valueTr=tr.value;
            const textTr=tr.text;
            return {[lang]:{value:valueTr,text:textTr},...accTr};
        },{});
        return {[key]:{value,text,translations},...acc};
    },{}) : {},[data]);
    const settings=useMemo(() => data ? data.settings : {}, [data]);
    useEffect(()=>{
        const root = document.documentElement;
        root.style.setProperty('--hover-color', settings.contrast_color || "#299DD8");
    },[settings]);
    const translate=useCallback((item,key)=>{
      return item ? Utils.translate(item,lang,key) : '';
    },[lang]);
    const t=useCallback((key,type='value')=>{
      const tr=trads[key] ? ( trads[key].translations[lang] || trads[key] ) : {value:null, text:null};
      return  tr[type] || '';
    },[trads,lang]);
    const removeScrollY=(pathname)=>{
      setAppState((state)=>{return{...state,pagesScrollY:{...state.pagesScrollY,[pathname]:null}}});
    };
    const settingsRefetch=()=>{
      refetch();
      setAppState(state=>{return{...state,refresh:state.refresh+1}});
    }
    const value={ settingsRefetch, removeScrollY, fetchedList, loadlist, updateLoadState, appState, setAppState, settings, trads, lang, setLang, t, filter, setFilter, translate, tick, setTick, currentRub, setCurrentRub};
    return (
        <SettingsContext.Provider value={value}>
            {data && children}
        </SettingsContext.Provider>
    );
}
export default SettingsProvider;
