import React, { useContext, useEffect, useMemo } from "react";
import './Rdv.scss'
import RdvTile from 'components/Rdv/RdvTile';
import Grid from '@material-ui/core/Grid';
import Queries from 'utils/Queries';
import { useQuery } from '@apollo/client';
import { DateTime } from "luxon";
import { SettingsContext } from 'Providers/SettingsProvider';
import 'components/CartoucheInfos/CartoucheInfos.scss';
import {ReactComponent as Carre} from 'imgs/Carre.svg';
import Pagination from 'components/Pagination';
import Conf from 'utils/Conf';
import Mod from 'components/Mod';

const parseDate=(rdv)=>DateTime.fromISO(rdv.date);

function Rdvs() {
  const { t, filter, setFilter, updateLoadState, fetchedList, appState, setAppState } = useContext(SettingsContext);
  const { refetch, networkStatus, data } = useQuery(Queries.rdvs);
  const uuid='rdvs';
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  let types= data ? data.types : [];
  const rdvs=useMemo(()=>{
    const tab=[];
    (data ? data.rdvs : []).forEach((rdv, i) => {
        const {date, time_from, time_to, repetitions, ...otherProps} = rdv;
        let currentRdv={...otherProps};
        if (
          (filter.archives && DateTime.fromISO(date).plus({ day: 1 })<DateTime.now())
          || (!filter.archives && DateTime.fromISO(date).plus({ day: 1 })>=DateTime.now())
        ) tab.push({...currentRdv, date, time_from, time_to});
        repetitions.forEach((r, i) => {
          if (
            (filter.archives && DateTime.fromISO(r.date).plus({ day: 1 })<DateTime.now())
            || (!filter.archives && DateTime.fromISO(r.date).plus({ day: 1 })>=DateTime.now())
          ) tab.push({...currentRdv, date:r.date, time_from:r.time_from, time_to:r.time_to});
        });
    });
    tab.sort((a,b)=>{
      const dateA=parseDate(a);
      const dateB=parseDate(b);
      if (dateA < dateB )
         return filter.archives ? 1 : -1;
      if (dateA > dateB )
         return filter.archives ? -1 : 1;
      return 0;
    });
    return tab;
  },[data,filter]);
  const touch=()=>{
    const page = 1;
    const touched=(appState.paginations[uuid] && appState.paginations[uuid].touched+1) || 1;
    setAppState((state)=>{return { ...state, paginations: { ...state.paginations, [uuid]:{page,touched} } }});
  }
  const handleTypeChange=(id)=>{
    const {types:filterTypes,...otherFilters}=filter;
    const newTypes=[...filterTypes];
    const idx=newTypes.indexOf(id);
    if (idx!==-1) newTypes.splice(idx,1);
    else newTypes.push(id);
    setFilter({types:newTypes,...otherFilters});
    touch();
  }
  const handleArchivesChange=()=>{
    const {archives,...otherFilters}=filter;
    setFilter({archives:!archives,...otherFilters});
    touch();
  }
  let classesArchives='filter-type archives';
  if (filter.archives) classesArchives+=" active";
  const rdvList=rdvs.filter((r)=>
    filter.types.length===0
    || filter.types.filter((f)=>{
      return r.types.findIndex((o)=>o.types_id && parseInt(o.types_id.id)===parseInt(f))!==-1
    }).length>0
  );
  const nb=rdvList ? rdvList.length : 0;
  const page = (appState.paginations[uuid] && appState.paginations[uuid].page) || 1;
  useEffect(()=>{
    document.title=t('rendez-vous')
  },[t]);
  return (<div className="rdvs">
  <Grid container spacing={2}>
    <Grid item xs={12} sm={8}>
      {nb>0 && <Pagination paginationId={uuid} page={page} nb={nb} typeSingulier='rendez-vous' typePluriel='rendez-vous' perPage={Conf.perPage}/>}
      <Grid container spacing={2}>
          {rdvList.map((rdv,i)=>{
            const from=(page-1)*Conf.perPage+1;
            const to=Math.min(nb,page*Conf.perPage);
            if (i+1>=from && i+1<=to) {
              return <Grid key={rdv.id+'-'+rdv.date+'-'+rdv.time_from} item xs={12} sm={6}>
                  <RdvTile rdv={rdv}/>
              </Grid>;
            }
            return null;
          })}
      </Grid>
    </Grid>
    <Grid item xs={12} sm={4}>
      <div className="filtres cartouche-infos">
        <div className="cartouche-infos-label">{t('filtres')}</div>
        {types.map((ty,i)=>{
          let classes='filter-type';
          if (filter.types.indexOf(ty.id)!==-1) classes+=" active";
          return <div key={i} className={classes} onClick={()=>handleTypeChange(ty.id)}><Carre/> {ty.name}</div>
        })}
        <div key={'archives'} className={classesArchives} onClick={()=>handleArchivesChange()}><Carre/> {t('archives')}</div>
      </div>
    </Grid>
  </Grid>
  <Mod url={'rdvs'} refresh={refetch}/>
  </div>);
}
export default Rdvs;
