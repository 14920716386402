import React, { useContext } from "react";
import './Rechercher.scss';
import SlugLink from 'components/Router/SlugLink';
import Grid from '@material-ui/core/Grid';
import Pagination from 'components/Pagination';
import Conf from 'utils/Conf';
import Utils from 'utils/Utils';
import { SettingsContext } from 'Providers/SettingsProvider';

const perPage=15;

function Result({collection,typeSingulier,typePluriel}) {
  const { appState,t,translate} = useContext(SettingsContext);
  const nb=collection ? collection.length : 0;
  const uuid='result-'+typeSingulier;
  const page = (appState.paginations[uuid] && appState.paginations[uuid].page) || 1;
  return nb>0 ? <div className="results">
      {nb>0 && <Pagination mini paginationId={uuid} page={page} nb={nb} typeSingulier={typeSingulier} typePluriel={typePluriel} perPage={perPage}/>}
      <div className="results-titre">{t(typePluriel)}</div>
      <Grid container spacing={0}>
        {collection.map((o,i)=>{
          const from=(page-1)*perPage+1;
          const to=Math.min(nb,page*perPage);
          if (i+1>=from && i+1<=to) {
            return <Grid key={o.id} item xs={12}>
              {typeSingulier!=='eac-doc' && <SlugLink item={o}><div className="result-item">
                {o.title}{o.artistes && o.artistes.length>0 && <>, {Utils.renderArtistes(o,false,false,'medium').value}</>}{o.types && <> ({o.types.map((type,i)=><span key={i}>{i>0 && ', '}{translate(type.types_id,'name')}</span>)})</>}
              </div></SlugLink>}
              {typeSingulier==='eac-doc' && o.document && <a href={Conf.apiUrl+'/assets/'+o.document.id+'?download'} target="_blank" rel="noreferrer">
                <div className="result-item">
                  {o.type.titre}, {o.title}
                </div>
              </a>}
            </Grid>;
          }
          return null;
        })}
      </Grid>
      <div className="spacer"/>
    </div> : null;
}

export default Result;
