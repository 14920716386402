import React, { useContext, useEffect } from "react";
import { useQuery } from '@apollo/client';
import './Exposition.scss'
import Queries from 'utils/Queries';
import Biographie from 'components/Artiste/Biographie';
import Ressources from 'components/Ressources';
import Partenaires from 'components/Partenaires';
import CartoucheInfos from 'components/CartoucheInfos';
import Utils from 'utils/Utils';
import Grid from '@material-ui/core/Grid';
import RdvTile from 'components/Rdv/RdvTile';
import BlogTile from 'components/Blog/BlogTile';
import { SettingsContext } from 'Providers/SettingsProvider';
import Image from 'components/Image';
import { useParams } from "react-router-dom";
import Mod from 'components/Mod';
import Share from 'components/Share';

import SwiperCore, { Navigation, Autoplay, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
SwiperCore.use([Navigation, Autoplay, A11y]);

function Expo() {
  const { slug }=useParams();
  const { translate, t, updateLoadState, fetchedList } = useContext(SettingsContext);
  const { refetch, networkStatus, data } = useQuery(Queries.exposition,{
    variables: { slug },
  });
  const uuid='exposition/'+slug;
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const expo=data ? data.expositions[0] : null;
  const dataExpo=expo ? [
    {key:'exposition',value:translate(expo,'title')},
    Utils.renderArtistes(expo,true,false),
    {key:null,value:Utils.dates(expo)},
    (expo.animation ? {animation:expo.animation} : {image:expo.image,credits:true,nocrop:expo.nocrop,visuel_background:expo.visuel_background}),
  ]: [];
  const rdvs=expo ? expo.rdvs.filter((o)=>o.rdvs_id && o.rdvs_id.agenda!=='blog') : [];
  const blogs=expo ? expo.rdvs.filter((o)=>o.rdvs_id && o.rdvs_id.agenda==='blog') : [];
  useEffect(()=>{
    if (expo && expo.title) document.title=expo.title
  },[expo]);
  return expo ? <div className="exposition">
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <CartoucheInfos data={dataExpo}/>
      </Grid>
      <Grid item xs={12} md={4}>
      </Grid>
    </Grid>
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <div className="exposition-text select">
          {Utils.parseWithLinks(expo ? (expo.text ? translate(expo,'text') : (expo.resume ? translate(expo,'resume'): '')) : '')}
        </div>
        { expo.diaporama && expo.diaporama.length>0 && <div className="exposition-diaporama">
          {
          expo.diaporama && expo.diaporama.length>0 ?
            <Swiper
            speed={800}
            loop
            autoplay={{
              delay: 3000,
            }}
            enabled={expo.diaporama.length>1}
            navigation={expo.diaporama.length>1}
            >
              {expo.diaporama.map((diap,i)=>{
                  const img=diap.directus_files_id;
                  return <SwiperSlide key={img.id}>
                    <Image credits file={img} thumbnailKey={"16-9"} ratio={0.5625}/>
                  </SwiperSlide>;
              })}
            </Swiper>
            : ''
          }
        </div>}
        <Share/>
        {expo.artistes.map((artiste)=>artiste.artistes_id && artiste.artistes_id.bio ? <Biographie key={artiste.artistes_id.id} artiste={artiste.artistes_id}/> : null)}
        <Ressources item={expo}/>
        <Partenaires item={expo}/>
      </Grid>
      <Grid item xs={12} md={4}>
        <div className="billets">
          {rdvs.length>0 && <div className="cartouche-infos">
            <div className="type-billet">{t('rendez-vous')}</div>
            {rdvs.map((rdv,i)=><RdvTile key={i} rdv={rdv.rdvs_id} size="mini"/>)}
          </div>}
          {rdvs.length>0 && <div className="spacer"/>}
          {blogs.length>0 && <div className="cartouche-infos">
            <div className="type-billet">{t('actualites')}</div>
            {blogs.map((blog,i)=><BlogTile key={i} blog={blog.rdvs_id} size="mini"/>)}
          </div>}
        </div>
      </Grid>
    </Grid>
    <Mod url={expo.__typename+'/'+expo.id} refresh={refetch}/>
  </div> : '';
}
export default Expo;
