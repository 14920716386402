import './App.scss';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Header from 'components/Header/Header';
import TopBar from 'components/TopBar/TopBar';
import Accueil from 'components/Accueil/Accueil';
import Rdvs from 'components/Rdv/Rdvs';
import Blogs from 'components/Blog/Blogs';
import Residences from 'components/Residences';
import Editions from 'components/Editions';
import Ac from 'components/Ac';
import Expositions from 'components/Expositions';
import Exposition from 'components/Exposition';
import Expos from 'components/Expositions/Expos';
import Expo from 'components/Exposition/Expo';
import Rubrique from 'components/Rubrique';
import Page from 'components/Page';
import Rdv from 'components/Rdv/Rdv';
import Blog from 'components/Blog/Blog';
import Residence from 'components/Residence/Residence';
import Edition from 'components/Edition/Edition';
import AcProject from 'components/Ac/AcProject';
import AcInstance from 'components/Ac/AcInstance';
import Results from 'components/Rechercher/Results';
import EacDocs from 'components/EacDocs';
import ScrollToTop from 'components/Router/ScrollToTop';
import Footer from 'components/Footer';
import SettingsProvider from "Providers/SettingsProvider";
import MenuLeft from 'components/MenuLeft';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#000',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#888',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  overrides: {
    // Style sheet name ⚛️
    MuiTabs: {
      // Name of the rule
      indicator: {
        // Some CSS
        height: 8,
      },
    },
  },
});

function App() {
  const localRoutes=[
    <Route key='main' path="/" element={<Accueil/>}/>,
    <Route key='expositions' path="/expositions" element={<Expositions/>}/>,
    <Route key='exposition' path="/expositions/:slug" element={<Exposition/>}/>,
    <Route key='expos' path="/expos" element={<Expos/>}/>,
    <Route key='expo' path="/expos/:slug" element={<Expo/>}/>,
    <Route key='rubrique' path="/rubriques/:slug" element={<Rubrique/>}/>,
    <Route key='page' path="/pages/:slug" element={<Page/>}/>,
    <Route key='rdvs' path="/rdvs" element={<Rdvs/>}/>,
    <Route key='rdv' path="/rdvs/:slug" element={<Rdv/>}/>,
    <Route key='blogs' path="/blog" element={<Blogs/>}/>,
    <Route key='blog' path="/blog/:slug" element={<Blog/>}/>,
    <Route key='editions' path="/editions" element={<Editions/>}/>,
    <Route key='edition' path="/editions/:slug" element={<Edition/>}/>,
    <Route key='ac' path="/education-artistique" element={<Ac/>}/>,
    <Route key='ac-project' path="/propositions_eac/:slug" element={<AcProject/>}/>,
    <Route key='ac-instance' path="/realisations_eac/:slug" element={<AcInstance/>}/>,
    <Route key='residences' path="/residences" element={<Residences/>}/>,
    <Route key='residence' path="/residences/:slug" element={<Residence/>}/>,
    <Route key='recherche' path="/recherche" element={<Results/>}/>,
    <Route key='ressources' path="/ressources" element={<EacDocs/>}/>,
  ];
  return (
    <ThemeProvider theme={theme}>
    <SettingsProvider>
    <Router>
      <Header/>
      <TopBar/>
      <div className="body-container">
      <Grid container spacing={2}>
          <Hidden smDown>
            <Grid item xs={12} md={3}>
                <MenuLeft/>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={9}>
              <Routes>
              {localRoutes}
              </Routes>
          </Grid>
      </Grid>
      </div>
      <Footer/>
      <ScrollToTop/>
    </Router>
    </SettingsProvider>
    </ThemeProvider>
  );
}

export default App;
