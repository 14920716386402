import React, { useContext, useEffect } from "react";
import { useQuery } from '@apollo/client';
import './Edition.scss'
import Queries from 'utils/Queries';
import Biographie from 'components/Artiste/Biographie';
import Ressources from 'components/Ressources';
import Partenaires from 'components/Partenaires';
import CartoucheInfos from 'components/CartoucheInfos';
import Utils from 'utils/Utils';
import Grid from '@material-ui/core/Grid';
import { SettingsContext } from 'Providers/SettingsProvider';
import Image from 'components/Image';
import RdvTile from 'components/Rdv/RdvTile';
import BlogTile from 'components/Blog/BlogTile';
import { useParams } from "react-router-dom";
import Mod from 'components/Mod';
import Share from 'components/Share';

import SwiperCore, { Navigation, Autoplay, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
SwiperCore.use([Navigation, Autoplay, A11y]);

function Edition() {
  const { slug }=useParams();
  const { t, translate, updateLoadState, fetchedList } = useContext(SettingsContext);
  const { refetch, networkStatus, data } = useQuery(Queries.edition,{
    variables: { slug },
  });
  const uuid='edition/'+slug;
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const edition=data ? data.editions[0] : null;
  const dataEdition=edition ? [
    {key:'edition',value:translate(edition,'title')},
    Utils.renderArtistes(edition,true,false),
  ]: [];
  const rdvs=edition ? edition.rdvs.filter((o)=>o.rdvs_id && o.rdvs_id.agenda!=='blog') : [];
  const blogs=edition ? edition.rdvs.filter((o)=>o.rdvs_id && o.rdvs_id.agenda==='blog') : [];
  useEffect(()=>{
    if (edition && edition.title) document.title=edition.title
  },[edition]);
  return edition ? <div className="edition">
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <CartoucheInfos data={dataEdition}/>
        <div className="cartouche-infos">
          <div className="cartouche-infos-image">
            {
            edition.diaporama && edition.diaporama.length>0 ?
              <div className="edition-diaporama">
                <Swiper
                speed={800}
                loop
                autoplay={{
                  delay: 3000,
                }}
                enabled={edition.diaporama.length>1}
                navigation={edition.diaporama.length>1}
                >
                  {edition.diaporama.map((diap,i)=>{
                      const img=diap.directus_files_id;
                      return <SwiperSlide key={img.id}>
                        <Image credits file={img} thumbnailKey={"16-9"} ratio={0.5625}/>
                      </SwiperSlide>;
                  })}
                </Swiper>
              </div>
              :
              edition.visuel ? <Image credits file={edition.visuel} thumbnailKey={"16-9"} ratio={0.5625} nocrop={edition.nocrop} background={edition.visuel_background} align='left'/> : null
            }
            </div>
          </div>
        </Grid>
      <Grid item xs={12} md={4}>
      </Grid>
    </Grid>
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <div className="edition-text select">
          {Utils.parseWithLinks(edition ? (edition.text ? translate(edition,'text') : (edition.resume ? translate(edition,'resume'): '')) : '')}
        </div>
        <Share/>
        {edition.artistes.map((artiste)=>artiste.artistes_id && artiste.artistes_id.bio ? <Biographie key={artiste.artistes_id.id} artiste={artiste.artistes_id}/> : null)}
        <Ressources item={edition}/>
        <Partenaires item={edition}/>
      </Grid>
      <Grid item xs={12} md={4}>
        {rdvs.length>0 && <div className="cartouche-infos">
          <div className="cartouche-infos-label">{t('rendez-vous')}</div>
          {rdvs.map((rdv,i)=><RdvTile key={i} rdv={rdv.rdvs_id} size="mini"/>)}
        </div>}
        {rdvs.length>0 && <div className="spacer"/>}
        {blogs.length>0 && <div className="cartouche-infos">
          <div className="cartouche-infos-label">{t('actualites')}</div>
          {blogs.map((blog,i)=><BlogTile key={i} blog={blog.rdvs_id} size="mini"/>)}
        </div>}
      </Grid>
    </Grid>
    <Mod url={edition.__typename+'/'+edition.id} refresh={refetch}/>
  </div> : '';
}
export default Edition;
